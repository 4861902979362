import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles from 'react-jss'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import { getSellerLink, getWindow } from '@elo-kit/utils/helpers.utils'
import { HEADER_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { patchLink } from 'utils/link.utils'

import headerSharingOptionsStyles from './HeaderSharingOptions.styles'

/**
 * Page Builder's Header Sharing component
 */
export class HeaderSharingOptionsContainer extends Component {
  getSharingOptions = () => {
    const {
      seller,
      rootUrl,
      locales = {
        seoText: this.props.I18n.t('react.shop.share'),
      },
    } = this.props
    const seoText = locales?.seoText
    const sellerLink = getSellerLink(seller)
    const mediaLink = `${rootUrl}/public/images/logo.svg`

    return [
      {
        url: `http://www.facebook.com/share.php?u=${sellerLink}&title=${seoText}`,
        icon: 'fab fa-facebook-square',
      },
      {
        url: `https://plus.google.com/share?url=${sellerLink}`,
        icon: 'fab fa-google-plus-square',
      },
      {
        url: `http://twitter.com/home?status=${seoText}+#{URI.escape(URI.escape(${sellerLink}))}`,
        icon: 'fab fa-twitter-square',
      },
      {
        url: `http://www.linkedin.com/shareArticle?mini=true&url=${sellerLink}&title=${seoText}&source=ablefy.io`,
        icon: 'fab fa-linkedin',
      },
      {
        url: `http://www.tumblr.com/share?v=3&u=${sellerLink}&t=${seoText}`,
        icon: 'fab fa-tumblr-square',
      },
      {
        url: `http://www.reddit.com/submit?url=${sellerLink}&title=${seoText}`,
        icon: 'fab fa-reddit-square',
      },
      {
        url: `http://pinterest.com/pin/create/bookmarklet/?media=${mediaLink}&url=${sellerLink}&is_video=false&description=${seoText}`,
        icon: 'fab fa-pinterest-square',
      },
    ]
  }

  render() {
    const { classes, previewMode } = this.props
    const sharingOptions = this.getSharingOptions()

    return (
      <div
        className={classNames(
          HEADER_CLASS_NAMES.socialItemWrapperContainerClassName,
          classes.socials,
          classes.sharingOptions
        )}
        {...(previewMode && {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.header.sharingOptions,
          'data-highlighter-selector': '',
        })}
      >
        <i className='fas fa-share-alt' />

        <div className={classNames(classes.sharingOptionsContainer, 'submenus')}>
          {sharingOptions.map((option) => (
            <button
              key={option.icon}
              onClick={() => {
                const protectedWindow = getWindow()
                protectedWindow?.open(
                  patchLink(option.url),
                  '',
                  'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
                )
              }}
              className={classNames(classes.submenuItem, classes.sharingOptionBtn)}
            >
              <i className={option.icon} />
            </button>
          ))}
        </div>
      </div>
    )
  }
}

HeaderSharingOptionsContainer.propTypes = {
  /** Seller details from contentPageStore */
  seller: PropTypes.object,
  /** Locales object */
  locales: PropTypes.object,
  /** React JSS styles */
  classes: PropTypes.object,
  /** Root URL */
  rootUrl: PropTypes.string,
  /** Indicates whether it's Page Builder preview of component */
  previewMode: PropTypes.bool,
}

HeaderSharingOptionsContainer.defaultProps = {
  seller: {},
  classes: {},
  rootUrl: '',
  previewMode: false,
  I18n: {
    t: () => {},
  },
}

const HeaderSharingOptionsWithStyles = withStyles(headerSharingOptionsStyles)(HeaderSharingOptionsContainer)

const HeaderSharingOptions = (props) => {
  const I18n = useI18n()

  return <HeaderSharingOptionsWithStyles I18n={I18n} {...props} />
}

export default HeaderSharingOptions
