import { getElopageConfig } from 'utils/elopageConfig.utils'
import { isWindowEnv } from 'utils/env.utils'
import { ENVIRONMENT } from 'constants/general.constants'
import { webHosts, newWebHosts, marketingWebHosts } from 'libs/configs'
const FEAppUrls = [
  '/users',
  '/admin',
  '/cabinet',
  '/elo_publisher',
  '/payer',
  '/publish',
  '/team_member',
  '/widget',
  '/v/',
  '/sales_team_member',
  '/terms',
  '/privacy',
  '/imprint',
  '/emails',
  '/users',
  '/email_logs',
  '/elopage_promos',
  '/v1/',
  '/v2/',
  '/v1.1/',
  '/epl/',
  '/s/',
  '/funnel_shim.js',
  '/funnel.js',
  '/widgets_shim.js',
  '/widgets.js',
  '/assets/',
]

export const patchLink = (link: string, currentSSRlink?: string): string => {
  if (!link || typeof link !== 'string') return link

  const isAbsoluteURL = link.startsWith('http')

  if (!isAbsoluteURL) return link

  if (!isWindowEnv() && !currentSSRlink) return link
  if (getElopageConfig('env') === ENVIRONMENT.development || getElopageConfig('env') === 'test') return link

  const marketingWebHost = getElopageConfig('marketingWebHost')
  const apiPath = getElopageConfig('apiPath')
  const apiDomain = new URL(apiPath).hostname
  const newApiPath = getElopageConfig('newApiPath')
  const newApiDomain = new URL(newApiPath).hostname
  const integrationService = getElopageConfig('integrationServiceHost')
  const integrationServiceHost = new URL(integrationService).hostname
  const newIntegrationService = getElopageConfig('newIntegrationServiceHost')
  const newIntegrationServiceHost = new URL(newIntegrationService).hostname
  const webHost = getElopageConfig('webHost')
  const newWebHost = getElopageConfig('newWebHost')

  const linkLocation = new URL(link)
  const currentLocation = !isWindowEnv() ? new URL(currentSSRlink) : new URL(window.location.href)
  const currentHost = currentLocation.hostname
  const isMyelopageSubdomain = linkLocation.hostname.includes('myelopage.com')
  const isMyablefySubdomain = linkLocation.hostname.includes('my-ablefy.com')

  if (currentHost === 'localhost') return link

  const isInternalLink = [
    apiDomain,
    newApiDomain,
    integrationServiceHost,
    newIntegrationServiceHost,
    webHost,
    webHosts.production,
    newWebHost,
    marketingWebHost,
    'api.staging.elopage.com',
    'api.uat.elopage.com',
    'api.elopage.com',
  ].includes(linkLocation.hostname)

  const isProductionLink = linkLocation.hostname === webHosts.production

  if (!isInternalLink && !isMyelopageSubdomain && !isMyablefySubdomain) return link

  const isApiDomain = [
    apiDomain,
    newApiDomain,
    'api.staging.elopage.com',
    'api.uat.elopage.com',
    'api.elopage.com',
  ].includes(linkLocation.hostname)
  const isApiPath = ['/v1/', '/v2/', '/v1.1/'].some((path) => linkLocation.pathname.startsWith(path))
  const isIntegrationServiceHost = [integrationServiceHost, newIntegrationServiceHost].includes(linkLocation.hostname)
  const isWebHost = [webHost, newWebHost].includes(linkLocation.hostname)
  const isOldDomain = currentHost === webHost || currentHost.includes('myelopage.com')
  const isMarketingLink = !FEAppUrls.some((path) => linkLocation.pathname.startsWith(path))

  if (isOldDomain) {
    if (isMyablefySubdomain || isMyelopageSubdomain) {
      linkLocation.hostname = linkLocation.hostname.replace('my-ablefy.com', 'myelopage.com')
    } else if (isApiDomain || isApiPath) {
      linkLocation.hostname = apiDomain
    } else if (isIntegrationServiceHost) {
      linkLocation.hostname = integrationServiceHost
    } else if (isWebHost) {
      linkLocation.hostname = webHost
    }
  } else {
    if (isMyelopageSubdomain || isMyablefySubdomain) {
      linkLocation.hostname = linkLocation.hostname.replace('myelopage.com', 'my-ablefy.com')
    } else if (isApiDomain || isApiPath) {
      linkLocation.hostname = newApiDomain
    } else if (isIntegrationServiceHost) {
      linkLocation.hostname = newIntegrationServiceHost
    } else if (isMarketingLink) {
      if (isProductionLink) {
        linkLocation.hostname = marketingWebHosts.production
      } else {
        linkLocation.hostname = marketingWebHost
      }
    } else if (isWebHost) {
      if (isProductionLink) {
        linkLocation.hostname = newWebHosts.production
      } else {
        linkLocation.hostname = newWebHost
      }
    }
  }

  return linkLocation.href.replace(/\/$/, '')
}

export const normalizeShopLink = (link: string): string => {
  if (!link || typeof link !== 'string') return link
  if (!link.startsWith('http') && !link.startsWith('/')) return link

  if (getElopageConfig('env') === 'development') {
    const webProtocol = getElopageConfig('webProtocol')
    const webHost = getElopageConfig('webHost')
    const isAbsoluteURL = link.startsWith('http')
    const absoluteURL = isAbsoluteURL ? link : `${webProtocol}://${webHost}${link}`
    const normalizedLink = new URL(absoluteURL)
    const isLinkToNextJSApp = normalizedLink.pathname.startsWith('/s/') || normalizedLink.pathname.startsWith('/epl')

    if (isLinkToNextJSApp) {
      normalizedLink.protocol = 'http'
      normalizedLink.hostname = 'localhost'
      normalizedLink.port = '8081'
    } else {
      normalizedLink.protocol = 'http'
      normalizedLink.hostname = 'localhost'
      normalizedLink.port = '8080'
    }

    return patchLink(normalizedLink.href)
  }

  return patchLink(link)
}
