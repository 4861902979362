import axios, { type AxiosInstance } from 'axios'
import Moment from 'moment'
import { stringify } from 'qs'
import * as NextJSSentry from '@sentry/nextjs'
import queryString from 'query-string'
import FormData from 'isomorphic-form-data'

import { getCookies, removeCookies, setCookies, userSessionCookieKey } from 'libs/common/cookies'
import { TEAM_MEMBER_PROFILE_PATH } from 'constants/routes/teamMember.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'
import { THEME_SHARING_PARAMS } from 'constants/themes.constants'
import { APP_USERS_SIGN_IN_PATH } from 'constants/routes/app.constants'
import { ELOPAGE_PREFIX, NEW_ELOPAGE_PREFIX } from 'constants/domains.constants'

import { createFingerPrint, FINGER_PRINT_KEY } from 'utils/fingerprint.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { profile } from 'utils/profileHelper.utils'
import { stringReplaceAll } from 'utils/string.utils'
import { isWindowEnv } from 'utils/env.utils'
import { LocalStorageService } from 'utils/local-storage.utils'
import { parseHeader } from 'utils/header'

import { notify } from 'libs/common/notify'

import { Response, HTTPResponse } from 'types/responses'
import { requestTimeInterceptor } from 'utils/api-client/request-inteceptors'
import { responseTimeInterceptor } from 'utils/api-client/response-inteceptors'
import { patchLink } from 'utils/link.utils'

import { interceptRequestForCancellation } from './request-cancelation.utils'
import { deepObjectPropNamesToCamelCase, deepObjectPropNamesToSnakeCase } from './nameStyle.utils'

interface ReservedOptions {
  __disableKeysConversion: string
  __disableResponseKeysConversion: string
}

function once(fn) {
  let done = false
  return function (...args) {
    if (!done) {
      done = true
      // @ts-ignore
      return fn.apply(this, args)
    }
  }
}

function withUserSessionId(axios: AxiosInstance, userSessionId?: string) {
  axios.interceptors.request.use(function (config) {
    const id = userSessionId ?? getCookies(userSessionCookieKey)

    if (id) {
      config.headers[userSessionCookieKey] = id
    }
    return config
  })
}

export class ApiClient {
  constructor(options?: { userLocale?: string; userSessionId?: string; debugMode?: boolean; SSRDomain?: string }) {
    this.options = { userLocale: options?.userLocale }
    /* Setup Axios listener for request cancellation */
    this.axiosInstance = axios.create()
    interceptRequestForCancellation(this.axiosInstance)

    if (getElopageConfig('isNextApp')) {
      withUserSessionId(this.axiosInstance, options?.userSessionId)
    }
    if (options?.debugMode) {
      this.debugMode = options.debugMode
      this.axiosInstance.interceptors.request.use(requestTimeInterceptor)
      this.axiosInstance.interceptors.response.use(responseTimeInterceptor)
    }
    if (options?.SSRDomain) {
      this.SSRDomain = options.SSRDomain
    }
  }
  private axiosInstance: AxiosInstance
  private options: { userLocale?: string }
  private refreshTokenProcessing = false
  private debugMode = false
  private serverSideLogs = []
  private accessTokenErrorTypes = ['expired_access_token', 'missing_access_token', 'invalid_access_token']
  private refreshTokenErrorTypes = ['expired_refresh_token', 'missing_refresh_token', 'invalid_refresh_token']
  private SSRDomain = null

  private paramsFactory = (method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE') => ({
    method,
    headers: {},
  })

  private PARAMS = {
    GET: this.paramsFactory('GET'),
    DELETE: this.paramsFactory('DELETE'),
    POST: this.paramsFactory('POST'),
    PUT: this.paramsFactory('PUT'),
    PATCH: this.paramsFactory('PATCH'),
  }

  private getReservedOptions = (params: Record<string, any> = {}): ReservedOptions => {
    const { __disableKeysConversion, __disableResponseKeysConversion } = params

    return { __disableKeysConversion, __disableResponseKeysConversion }
  }

  private isFile = (value) => isWindowEnv() && value instanceof File

  private modifyFormData = (object, form, namespace, unsafeOptions: any = {}) => {
    const { allowEmptyArray, deep } = unsafeOptions || {}
    let formKey

    for (const property in object) {
      // eslint-disable-next-line no-prototype-builtins
      if (object.hasOwnProperty(property)) {
        formKey = namespace ? `${namespace}[${property}]` : property

        if (object[property] instanceof Date) {
          form.append(formKey, object[property].toISOString())
        }

        if (object[property] instanceof Moment) {
          form.append(formKey, object[property].valueOf())
        } else if (object[property] === null || object[property] === undefined) {
          form.append(formKey, '')
        } else if (object[property] instanceof Array) {
          if (object[property].length) {
            for (let i = 0; i < object[property].length; i += 1) {
              if (typeof object[property][i] === 'object' && !this.isFile(object[property])) {
                const unsafeOptionsDeep = deep ? unsafeOptions : {}
                this.modifyFormData(object[property][i], form, `${formKey}[]`, unsafeOptionsDeep)
              } else {
                form.append(`${formKey}[]`, object[property][i])
              }
            }
          } else if (allowEmptyArray) {
            form.append(`${formKey}[]`, [])
          }
        } else if (typeof object[property] === 'object' && !this.isFile(object[property])) {
          const unsafeOptionsDeep = deep ? unsafeOptions : {}
          this.modifyFormData(object[property], form, formKey, unsafeOptionsDeep)
        } else {
          form.append(formKey, typeof window !== 'undefined' ? object[property] : `${object[property]}`)
        }
      }
    }

    return form
  }

  private createFormData = (object, unsafeOptions: Record<string, any> = {}) => {
    const convertedObject = unsafeOptions.disableSnakeCaseConverting ? object : deepObjectPropNamesToSnakeCase(object)

    return this.modifyFormData(convertedObject, new FormData(), '', unsafeOptions)
  }

  private formParams = (params, { __unsafeOptions = {}, ...body }: Record<string, any> = {}, token?: string) => {
    // do not use Cookies on SSR
    const auth = !isWindowEnv() ? token : (getCookies('access_token') as string)
    const { headers } = params || {}
    const { Authorization } = headers || {}

    if (auth && auth.length) {
      params = {
        ...params,
        ...{
          headers: {
            ...headers,
            Authorization: Authorization || (__unsafeOptions.hasBearer ? `Bearer ${auth}` : `${auth}`),
          },
          ...(Authorization ? { data: null } : {}),
        },
      }
    }

    if (params.method !== 'GET' && body !== undefined && (__unsafeOptions.passBodyToAuthorization || !Authorization)) {
      params = {
        ...params,
        ...{
          data: __unsafeOptions.bodyStringified ? body.body : this.createFormData(body, __unsafeOptions),
        },
      }
    }

    if (__unsafeOptions && typeof __unsafeOptions === 'object') {
      params = {
        ...params,
        ...__unsafeOptions,
      }
    }

    if (__unsafeOptions.bodyStringified) {
      params.headers = {
        ...params.headers,
        'Content-type': 'application/x-www-form-urlencoded',
      }
    }

    if (__unsafeOptions.asJson) {
      params.headers = {
        ...params.headers,
        'Content-type': 'application/json',
      }
      params.data = __unsafeOptions.disableSnakeCaseConverting ? body : deepObjectPropNamesToSnakeCase(body)
    }

    params.headers = {
      ...params.headers,
      ...{
        'Content-Language': this.options?.userLocale ?? I18n.locale,
        'Accept-Language': this.options?.userLocale ?? I18n.locale,
      },
    }

    return params
  }

  private fullUrl = (url: string, ssr = true) => {
    const endpoint = this.getDevApiEndpoint()
    const alreadyFullUrl = /^https?/.test(url)
    const isProfileEditPage = isWindowEnv() && window.location.pathname === TEAM_MEMBER_PROFILE_PATH
    let apiPath = patchLink(getElopageConfig('apiPath'), this.SSRDomain)
    let apiVersion = getElopageConfig('apiVersion')
    const env = getElopageConfig('env')
    const isNextApp = getElopageConfig('isNextApp')

    if (isNextApp && ssr && !isWindowEnv()) {
      apiPath = patchLink(getElopageConfig('apiPathSSR'), this.SSRDomain)
    }

    if (endpoint && env !== 'production') {
      apiPath = endpoint.replace(/\/$/, '')
    }

    // temp solution to skip adding version
    if (url.indexOf('~') >= 0) {
      apiVersion = ''
      url = url.replace('~', '')
    }

    if (url.startsWith('/v2/')) {
      apiVersion = ''
    }

    if (url.startsWith('/v1.1/')) {
      apiVersion = ''
    }

    // solution for team members API overwrites
    if (profile.profileType === 'team_member' && profile.tmSelectedSeller && !isProfileEditPage) {
      url = stringReplaceAll(url, '/cabinet/', `/team_member/sellers/${profile.tmSelectedSeller}/`)
      url = stringReplaceAll(url, '/common/seller', `/team_member/sellers/${profile.tmSelectedSeller}/seller`)
    }
    return alreadyFullUrl ? url : apiPath + apiVersion + url
  }

  private request = <T>(url: string, params: any = {}, toLogs?: any): Promise<T> =>
    this.axiosInstance
      .request<T>({ url: this.fullUrl(url), ...params })
      .then((resp) => {
        if (this.debugMode) {
          const reqHeaders = parseHeader(resp.request._header)
          const resHeaders = resp.headers
          const respStatus = resp.status
          const reqDuration = resp.headers['request-duration']

          const logs = JSON.stringify({
            method: params.method,
            url: this.fullUrl(url),
            response: resp.data,
            path: url,
            reqHeaders,
            resHeaders,
            respStatus,
            reqDuration,
          })
          this.serverSideLogs.push(logs)
        }

        return resp
      })
      .catch((error) => {
        if (this.debugMode) {
          const reqHeaders = parseHeader(error.response.request._header)
          const resHeaders = error.response.headers
          const respStatus = error.response.status
          const reqDuration = error.response.headers['request-duration']

          const logs = JSON.stringify({
            method: params.method,
            url: this.fullUrl(url),
            response: error.response.data,
            path: url,
            reqHeaders,
            resHeaders,
            respStatus,
            reqDuration,
          })
          this.serverSideLogs.push(logs)
        }

        const isNextApp = getElopageConfig('isNextApp')

        if (isNextApp) {
          const status = error?.response?.status
          // ignore unauthorised res from /common/user endpoint - it is expected
          const ignoreErrorStatus = status === 400 || status === 401 || status === 404

          if (status === 429) {
            // TODO: SSR - need to investigate why refreshTokens makes infinit loop
            this.removeTokens()
            removeCookies(FINGER_PRINT_KEY)
          }

          if (!ignoreErrorStatus) {
            NextJSSentry.captureException(error)

            // eslint-disable-next-line
            console.log('=============  axios error start ==============')
            // eslint-disable-next-line
            console.log(error?.message)
            // eslint-disable-next-line
            console.log('code:', error?.code)
            // eslint-disable-next-line
            console.log('method:', error?.config?.method)
            // eslint-disable-next-line
            console.log('url:', error?.config?.url)
            // eslint-disable-next-line
            console.log('data:', JSON.stringify(toLogs))
            // eslint-disable-next-line
            console.log('=============  axios error end ==============')
          }
        }

        return Object.prototype.hasOwnProperty.call(error, 'response') && error.response ? error.response : null
      })

  private handleResponse = <T>(
    resp,
    call,
    { __disableKeysConversion, __disableResponseKeysConversion } = {
      __disableKeysConversion: null,
      __disableResponseKeysConversion: null,
    }
  ): Promise<T> => {
    if (!resp) {
      // it is canceled request
      return {} as Promise<T>
    }
    if (resp.status === 204) {
      return {} as Promise<T>
    }

    if (resp.status === 429) {
      notify('error', I18n.t('react.shared.notify.retry_later'))
      return {} as Promise<T>
    }

    let convertedData = __disableKeysConversion ? resp.data : deepObjectPropNamesToCamelCase(resp.data)

    if (__disableResponseKeysConversion) {
      const list = resp.data.data.list
      const transformedResp = deepObjectPropNamesToCamelCase(resp.data)
      convertedData = {
        ...transformedResp,
        data: {
          ...transformedResp.data,
          list: list,
        },
      }
    }

    if (isWindowEnv()) {
      const auth = this.authenticationHandler(resp, convertedData, call)
      if (auth) {
        return auth as Promise<T>
      }
    }

    return convertedData as Promise<T>
  }

  private callByTimeout = async (call) => {
    const repeatRequestDelay = 500
    await new Promise((resolve) => setTimeout(resolve, repeatRequestDelay))

    if (this.refreshTokenProcessing) {
      return this.callByTimeout(call)
    }

    return call()
  }

  private checkTokens = (resp, data) => {
    const { status } = resp

    const [status400, status401, status404] = [400, 401, 404].map((code) => status === code)
    const failedRequest = status400 || status401 || status404
    const errorTypeExists = data.error && data.error.type

    const accessTokenError = failedRequest && errorTypeExists && this.accessTokenErrorTypes.includes(data.error.type)
    const refreshTokenError = failedRequest && errorTypeExists && this.refreshTokenErrorTypes.includes(data.error.type)

    return { accessTokenError, refreshTokenError }
  }

  private authenticationHandler = (resp, data, call: any) => {
    if (!isWindowEnv()) {
      return resp
    }
    const { accessTokenError, refreshTokenError } = this.checkTokens(resp, data)

    if (refreshTokenError) {
      this.removeTokens()
      return resp
    }

    const refreshToken = getCookies('refresh_token') as string
    const refreshTokenExists = refreshToken && refreshToken.length

    if (accessTokenError) {
      if (refreshTokenExists) {
        if (!this.refreshTokenProcessing) {
          if (isWindowEnv()) {
            return this.refreshTokens()?.then(() => {
              this.refreshTokenProcessing = false

              return call()
                .then((resp) => this.handleResponse(resp, call))
                .catch((err) => err)
            })
          }
        }

        return this.callByTimeout(call)
          .then((resp) => this.handleResponse(resp, call))
          .catch((err) => err)
      }

      this.removeTokens()
    }
  }

  private isErrorResponse = (resp): resp is HTTPResponse => resp && resp.error

  getDevApiEndpoint = () => {
    if (!isWindowEnv()) return ''
    const { elo_api } = queryString.parse(window.location.search)

    return (elo_api as string) || LocalStorageService.getItem('api_endpoint')
  }

  getMP3dsBrowserMetaData = () => {
    const { colorDepth: screenColorDepth, height: screenHeight, width: screenWidth } = window.screen

    /**
     * this is the regexp that must be matched by color_depth value: ^(1|4|8|15|16|24|32|48)$
     * window.screen.colorDepth at Chrome and Opera browsers at macOS equals to 30
     * */
    const CHROME_AND_OPERA_COLOR_DEPTH = 30
    const MP_ALLOWED_COLOR_DEPTH_FOR_CHROME_AND_OPERA = 32

    const colorDepth =
      screenColorDepth === CHROME_AND_OPERA_COLOR_DEPTH ? MP_ALLOWED_COLOR_DEPTH_FOR_CHROME_AND_OPERA : screenColorDepth

    return {
      browserInfo: {
        colorDepth,
        screenHeight,
        screenWidth,
        timeZoneOffset: new Date().getTimezoneOffset(),
        language: navigator.language,
        javaEnabled: navigator.javaEnabled(),
        javaScriptEnabled: true,
        userAgent: navigator.userAgent,
      },
    }
  }

  redirectToSignIn = (shouldSetRefererLink = true) => {
    if (!isWindowEnv()) return
    const [profileType, s, username, courses, lesson] = window.location.pathname.split('/').filter(Boolean)
    const redirectToShopSignIn = profileType === 'payer' && s === 's' && courses === 'courses' && !!username && !!lesson
    const refererLink = window.location.href
    const sessionStorage = navigator.cookieEnabled && window.sessionStorage
    const { hostname } = window.location
    const newDomain = getElopageConfig('newWebHost') || ''
    const isNewHostname = hostname === newDomain

    const domain = isNewHostname ? getElopageConfig('newCookiesDomain') : getElopageConfig('cookiesDomain') || ''
    const webProtocol = getElopageConfig('webProtocol')
    const webHost = isNewHostname ? getElopageConfig('newWebHost') : getElopageConfig('webHost')

    const isCustomDomain =
      !hostname.includes(domain.replace(/^\.?/, '')) ||
      hostname.includes(ELOPAGE_PREFIX) ||
      hostname.includes(NEW_ELOPAGE_PREFIX)
    const appSignInRoute = isCustomDomain
      ? patchLink(`${webProtocol}://${webHost}${APP_USERS_SIGN_IN_PATH}`)
      : APP_USERS_SIGN_IN_PATH

    if (shouldSetRefererLink && sessionStorage) sessionStorage.setItem('referer_link', refererLink)
    window.location.href = patchLink(redirectToShopSignIn ? `/s/${username}/sign_in` : appSignInRoute)
  }

  handleProfileFetchFailure = (userStore) => {
    if (userStore.item.id) {
      const sessionStorage = isWindowEnv() && navigator.cookieEnabled && window.sessionStorage
      if (sessionStorage) sessionStorage.removeItem('referer_link')
      window.location.href = patchLink(userStore.getCabinetLink())
    } else {
      this.redirectToSignIn()
    }
  }

  handleInitCabinetFetching = (userStore, profileStore, customHandling) => {
    Promise.all([userStore.fetchItem(), profileStore.fetchItem()]).then(([userResp, profileResp]) => {
      if (userResp.success) {
        // TODO: remove redundant function and use object from query
        const getParamsFromUrl = (params): Record<string, any> => {
          const paramsList = {}
          const searchParams = queryString.parse(window.location.search)
          params.forEach((paramName) => {
            if (searchParams[paramName]) {
              paramsList[paramName] = searchParams[paramName]
            }
          })
          return paramsList
        }
        const urlParams = getParamsFromUrl(THEME_SHARING_PARAMS)
        const themeToken = urlParams.shared_theme_token || getCookies('shared_theme_token')
        const themeType = urlParams.shared_theme_type || getCookies('shared_theme_type')
        const isSellerProfile = profile.profileType === ACTIVE_PROFILES.seller

        if (themeToken && themeType && !isSellerProfile) {
          const hasSeller = userStore.item.profileTypes.find((profile) => profile === ACTIVE_PROFILES.seller)

          if (hasSeller) {
            profile.setProfileType(ACTIVE_PROFILES.seller)

            window.location.assign('/cabinet')
          } else {
            userStore.toggleBecomeSellerModal(false)
          }
        }

        customHandling ? customHandling(profileResp) : !profileResp.success && this.handleProfileFetchFailure(userStore)
      }
    })
  }

  withFingerprint = (params) => {
    try {
      let fingerprint = getCookies(FINGER_PRINT_KEY)

      if (fingerprint) {
        return {
          ...params,
          fingerprint,
        }
      }

      fingerprint = createFingerPrint()

      setCookies(FINGER_PRINT_KEY, fingerprint)

      return {
        ...params,
        fingerprint,
      }
    } catch {
      return {
        ...params,
        fingerprint: null,
      }
    }
  }

  appendParamsIfPresent = (url: string, params?: Record<string, any>, enableSnakeCaseConverting = true) => {
    const paramsToSend = enableSnakeCaseConverting ? deepObjectPropNamesToSnakeCase(params) : params
    const paramsStringified = stringify(paramsToSend, { arrayFormat: 'brackets' })

    return paramsStringified ? `${url}?${paramsStringified}` : url
  }

  setTokens = async (resp) => {
    if (!isWindowEnv()) return
    // shorcut to debbug expired access tokens
    // const now = new Date()
    // now.setSeconds(now.getSeconds() + 10)
    // const expires = now

    const expires = new Date(resp.expiresAt)
    const refreshExpires = new Date(resp.refreshExpiresAt)

    await setCookies('access_token', resp?.accessToken, { expires })
    await setCookies('refresh_token', resp?.refreshToken, { expires: refreshExpires })
  }

  removeTokens = (shouldSetRefererLink?: boolean) => {
    if (!isWindowEnv()) return

    removeCookies('access_token')
    removeCookies('refresh_token')
    removeCookies('admin_access_token')
    removeCookies('tm_selected_seller')
    removeCookies('cabinet_profile_type')

    const isNextApp = getElopageConfig('isNextApp')
    const freeAccessBundles = ['app', 'shop']
    // @ts-ignore
    const isFreeAccess = freeAccessBundles.includes(document.head.querySelector('[property~=bundle][content]').content)
    if (!isFreeAccess && !isNextApp) this.redirectToSignIn(shouldSetRefererLink)
  }

  refreshTokensRequest = (params?: any) => {
    const refreshToken = getCookies('refresh_token')

    this.refreshTokenProcessing = true

    // TODO:
    return this.request<Response<any>>(
      '/app/session/renew_token',
      isWindowEnv()
        ? {
            ...this.PARAMS.POST,
            ...{
              data: this.createFormData(this.withFingerprint({ refreshToken })),
            },
          }
        : {
            ...this.PARAMS.POST,
            ...{
              data: this.createFormData({
                refreshToken: params?.refreshToken,
                fingerprint: params?.fingerprint,
              }),
            },
          }
    )
      .then(async (resp) => {
        if (resp.status === 200 || resp.statusText === 'OK') {
          const { accessTokenError, refreshTokenError } = this.checkTokens(resp, resp.data)

          if (!accessTokenError && !refreshTokenError) {
            await this.setTokens(deepObjectPropNamesToCamelCase(resp.data))
          }
        } else {
          this.removeTokens()
          removeCookies(FINGER_PRINT_KEY)
        }
        return resp
      })
      .catch((err) => err)
      .finally(() => {
        this.refreshTokenProcessing = false
      })
  }

  refreshTokens = once(this.refreshTokensRequest)

  GET_REQUEST = <T>(url: string, params): Promise<T> => {
    const { ip, ...restParams } = params || {}
    return this.request<T>(
      this.appendParamsIfPresent(url, restParams),
      this.formParams(
        {
          ...this.PARAMS.GET,
          headers: {
            'X-Forwarded-For': ip,
          },
        },
        restParams,
        params?.token
      )
    )
  }
  GET_EXTERNAL_REQUEST = <T>(url: string, params) =>
    this.request<T>(
      this.appendParamsIfPresent(url, { ...params, authorization: null }),
      this.formParams({
        ...this.PARAMS.GET,
        headers: {
          Authorization: params.authorization,
          'Content-Type': 'application/json',
        },
      })
    )
  POST_REQUEST = <T>(url: string, body: Record<string, any>, params) =>
    this.request<T>(this.appendParamsIfPresent(url, params), this.formParams(this.PARAMS.POST, body), body)
  POST_EXTERNAL_REQUEST = <T>(url: string, body: Record<string, any>, params) =>
    this.request<T>(
      this.appendParamsIfPresent(url),
      this.formParams(
        {
          ...this.PARAMS.POST,
          headers: {
            Authorization: params.serverKey,
            'Content-Type': 'application/json',
          },
        },
        {}
      ),
      body
    )
  PUT_REQUEST = <T>(url: string, body: Record<string, any>, params) =>
    this.request<T>(this.appendParamsIfPresent(url, params), this.formParams(this.PARAMS.PUT, body), body)
  PATCH_REQUEST = <T>(url: string, body: Record<string, any>, params) =>
    this.request<T>(this.appendParamsIfPresent(url, params), this.formParams(this.PARAMS.PATCH, body), body)
  DELETE_REQUEST = <T>(url: string, params) =>
    this.request<T>(this.appendParamsIfPresent(url, params), this.formParams(this.PARAMS.DELETE))
  DELETE_EXTERNAL_REQUEST = <T>(url: string, params) =>
    this.request<T>(
      this.appendParamsIfPresent(url, { ...params, authorization: null }),
      this.formParams({
        ...this.PARAMS.DELETE,
        headers: {
          Authorization: params.authorization,
          'Content-Type': 'application/json',
        },
      })
    )

  handlerError = async <T extends HTTPResponse>(
    call: () => Promise<T>,
    params: Record<string, any> = {}
  ): Promise<T> => {
    let resp: T
    const { skipErrorNotific } = params || {}
    try {
      resp = await call()
    } catch (err) {
      isWindowEnv() && !skipErrorNotific && notify('error', err)
      return err as T
    }

    if (this.isErrorResponse(resp)) {
      const error = typeof resp.error === 'string' ? resp.error : resp.error.message || resp.error.type || resp.error[0]
      const isTokenError =
        typeof resp.error !== 'string' &&
        resp.error.type &&
        (this.accessTokenErrorTypes.includes(resp.error.type) || this.refreshTokenErrorTypes.includes(resp.error.type))

      if (error && error.length && !isTokenError && !skipErrorNotific && isWindowEnv()) {
        notify('error', error)
      }
    }

    return resp
  }

  GET = <T extends HTTPResponse>(url: string, params?: Record<string, any>, external?: boolean) =>
    this.handlerError<T>(
      () => {
        const call = () => (external ? this.GET_EXTERNAL_REQUEST(url, params) : this.GET_REQUEST(url, params))
        const reservedOptions = this.getReservedOptions(params)
        return call().then((resp): Promise<T> => this.handleResponse(resp, call, reservedOptions))
      },
      { ...params, url }
    )

  POST = <T extends HTTPResponse>(
    url: string,
    body?: Record<string, any>,
    params: Record<string, any> = {},
    external?: boolean
  ): Promise<T> => {
    const { skipErrorNotific, ...rest } = params

    return this.handlerError(
      () => {
        const call = () =>
          external ? this.POST_EXTERNAL_REQUEST<T>(url, body, rest) : this.POST_REQUEST<T>(url, body, rest)
        const reservedOptions = this.getReservedOptions(body)

        return call().then((resp) => this.handleResponse(resp, call, reservedOptions)) as Promise<T>
      },
      {
        ...body,
        skipErrorNotific,
        url,
      }
    )
  }

  POST_WISTIA_PROGRESS = <T extends HTTPResponse>(
    url: string,
    body?: Record<string, any>,
    handleProgress?: boolean,
    params?: Record<string, any>
  ) =>
    this.handlerError<T>(
      () => {
        const { accessToken, ...restParams } = params || {}
        const call = () =>
          this.request(
            this.appendParamsIfPresent(url, restParams),
            this.formParams(
              {
                ...this.PARAMS.POST,
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
              },
              {
                ...body,
                __unsafeOptions: {
                  ...body.__unsafeOptions,
                  onUploadProgress: handleProgress,
                },
              }
            )
          )

        const reservedOptions = this.getReservedOptions(body)

        return call().then((resp) => this.handleResponse(resp, call, reservedOptions))
      },
      { ...body, url }
    )

  POST_WITH_PROGRESS = <T extends HTTPResponse>(
    url: string,
    body?: Record<string, any>,
    handleProgress?: (arg: { loaded: boolean; total: number }) => void,
    params?: Record<string, any>
  ) =>
    this.handlerError(
      (): Promise<T> => {
        const call = () =>
          this.POST_REQUEST<T>(
            url,
            {
              ...body,
              __unsafeOptions: {
                ...body.__unsafeOptions,
                onUploadProgress: handleProgress,
              },
            },
            params
          )
        const reservedOptions = this.getReservedOptions(body)

        return call().then((resp) => this.handleResponse(resp, call, reservedOptions))
      },
      { ...body, url }
    )

  PUT = <T extends HTTPResponse>(
    url: string,
    body?: Record<string, any>,
    params: Record<string, any> = {}
  ): Promise<T> => {
    const { skipErrorNotific, ...rest } = params

    return this.handlerError<T>(
      () => {
        const call = () => this.PUT_REQUEST<T>(url, body, rest)
        const reservedOptions = this.getReservedOptions(body)

        return call().then((resp) => this.handleResponse(resp, call, reservedOptions))
      },
      {
        ...body,
        skipErrorNotific,
        url,
      }
    )
  }

  PUT_WITH_PROGRESS = <T extends HTTPResponse>(
    url: string,
    body?: Record<string, any>,
    handleProgress?: boolean,
    params?: Record<string, any>
  ) =>
    this.handlerError<T>(
      () => {
        const call = () =>
          this.PUT_REQUEST<T>(
            url,
            {
              ...body,
              __unsafeOptions: {
                ...body.__unsafeOptions,
                onUploadProgress: handleProgress,
              },
            },
            params
          )
        const reservedOptions = this.getReservedOptions(body)

        return call().then((resp) => this.handleResponse(resp, call, reservedOptions))
      },
      { ...body, url }
    )

  PATCH = <T extends HTTPResponse>(url: string, body?: Record<string, any>, params?: Record<string, any>): Promise<T> =>
    this.handlerError<T>(
      () => {
        const call = () => this.PATCH_REQUEST<T>(url, body, params)
        const reservedOptions = this.getReservedOptions(body)

        return call().then((resp) => this.handleResponse(resp, call, reservedOptions))
      },
      { ...body, url }
    )

  PATCH_WITH_PROGRESS = <T extends HTTPResponse>(
    url: string,
    body?: Record<string, any>,
    handleProgress?: boolean,
    params?: Record<string, any>
  ) =>
    this.handlerError<T>(
      () => {
        const call = () =>
          this.PATCH_REQUEST(
            url,
            {
              ...body,
              __unsafeOptions: {
                ...body.__unsafeOptions,
                onUploadProgress: handleProgress,
              },
            },
            params
          )
        const reservedOptions = this.getReservedOptions(body)

        return call().then((resp) => this.handleResponse(resp, call, reservedOptions))
      },
      { ...body, url }
    )

  DELETE = <T extends HTTPResponse>(url: string, params?: Record<string, any>, external?: boolean) =>
    this.handlerError(
      () => {
        const call = () =>
          external ? this.DELETE_EXTERNAL_REQUEST<T>(url, params) : this.DELETE_REQUEST<T>(url, params)
        const reservedOptions = this.getReservedOptions(params)

        return call().then((resp) => this.handleResponse(resp, call, reservedOptions) as Promise<T>)
      },
      { ...params, url }
    )

  GET_API_URL = (url: string, params?: Record<string, any>, enableSnakeCaseConverting?: boolean) =>
    this.fullUrl(this.appendParamsIfPresent(url, { ...params }, enableSnakeCaseConverting), false)
}

export const apiClient = new ApiClient()

export const getDevApiEndpoint = apiClient.getDevApiEndpoint
export const redirectToSignIn = apiClient.redirectToSignIn
export const handleProfileFetchFailure = apiClient.handleProfileFetchFailure
export const handleInitCabinetFetching = apiClient.handleInitCabinetFetching
export const withFingerprint = apiClient.withFingerprint
export const appendParamsIfPresent = apiClient.appendParamsIfPresent
export const setTokens = apiClient.setTokens
export const removeTokens = apiClient.removeTokens
export const refreshTokensRequest = apiClient.refreshTokensRequest
export const getMP3dsBrowserMetaData = apiClient.getMP3dsBrowserMetaData
export const handlerError = apiClient.handlerError

export const GET = apiClient.GET
export const GET_REQUEST = apiClient.GET_REQUEST
export const GET_EXTERNAL_REQUEST = apiClient.GET_EXTERNAL_REQUEST
export const GET_API_URL = apiClient.GET_API_URL
export const POST = apiClient.POST
export const POST_REQUEST = apiClient.POST_REQUEST
export const POST_EXTERNAL_REQUEST = apiClient.POST_EXTERNAL_REQUEST
export const POST_WISTIA_PROGRESS = apiClient.POST_WISTIA_PROGRESS
export const POST_WITH_PROGRESS = apiClient.POST_WITH_PROGRESS
export const PUT = apiClient.PUT
export const PUT_REQUEST = apiClient.PUT_REQUEST
export const PUT_WITH_PROGRESS = apiClient.PUT_WITH_PROGRESS
export const PATCH = apiClient.PATCH
export const PATCH_REQUEST = apiClient.PATCH_REQUEST
export const PATCH_WITH_PROGRESS = apiClient.PATCH_WITH_PROGRESS
export const DELETE = apiClient.DELETE
export const DELETE_REQUEST = apiClient.DELETE_REQUEST
export const DELETE_EXTERNAL_REQUEST = apiClient.DELETE_EXTERNAL_REQUEST
