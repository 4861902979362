import React, { useEffect, useState } from 'react'

import EloModal from 'ui/EloModal'
import { TextField } from '@elo-kit/components/elo-ui/text-field/TextField'
import { EloButton } from '@elo-kit/components/elo-button/EloButton'

import { isProduction, isUat } from 'utils/env.utils'
import { getDevApiEndpoint } from 'utils/requests.utils'
import { setElopageConfig, getElopageConfig } from 'utils/elopageConfig.utils'
import { LocalStorageService } from 'utils/local-storage.utils'
import { patchLink } from 'utils/link.utils'
import './api-branch.scss'

const DEFAULT_ENDPOINT = getElopageConfig('apiPath')
const TEXTS = {
  title: 'Current endpoint: ',
  SSRTitle: 'Current SSR endpoint: ',
  button: 'Change',
  modalTitle: 'Change API endpoint',
  modalInputPlaceholder: 'Enter new endpoint',
  modalResetButton: 'Reset',
}

const ApiBranch: React.FC<{ showSrrUrl?: boolean; ssrApiUrl?: string }> = ({ showSrrUrl, ssrApiUrl }) => {
  if (isProduction()) return null

  const devEndpoint = getDevApiEndpoint()
  const [showModal, setShowModal] = useState(false)
  const [currentEndpoint, setEndPoint] = useState(devEndpoint)
  const [inputValue, setInputValue] = useState(currentEndpoint)

  useEffect(() => {
    const apiEndpointFromLocalStorage = LocalStorageService.getItem('api_endpoint')

    if (!apiEndpointFromLocalStorage) {
      updateEndPoint(patchLink(DEFAULT_ENDPOINT))
    }
  }, [])

  const updateEndPoint = (value) => {
    if (!isUat()) {
      LocalStorageService.setItem('api_endpoint', value)
      setElopageConfig('apiPath', value)
      setEndPoint(value)
    }
  }

  const handleReset = () => setInputValue(patchLink(DEFAULT_ENDPOINT))

  const handleSubmit = () => {
    updateEndPoint(inputValue)
    setShowModal(false)
  }

  const handleToggle = () => {
    setInputValue(currentEndpoint)
    setShowModal(!showModal)
  }

  const handleChange = (value) => setInputValue(value)

  return (
    <div className='change-api'>
      <div className='change-api__content'>
        <div>
          <span>
            {TEXTS.title}
            <b className='change-api__link'>{currentEndpoint}</b>
          </span>
          <button className='change-api__button' onClick={handleToggle}>
            {TEXTS.button}
          </button>
        </div>
        <div>
          {showSrrUrl && (
            <span>
              {TEXTS.SSRTitle}
              <b className='change-api__link'>{ssrApiUrl ?? getElopageConfig('apiPathSSR')}</b>
            </span>
          )}
        </div>
      </div>

      <EloModal
        className='elo-modal elo-modal--medium change-api__modal'
        title={TEXTS.modalTitle}
        submitDisabled={!currentEndpoint}
        isOpen={showModal}
        submit={handleSubmit}
        toggle={handleToggle}
      >
        <div className='change-api__controls'>
          <TextField value={inputValue} placeholder={TEXTS.modalInputPlaceholder} onChange={handleChange} />
          <EloButton onClick={handleReset} outlined>
            {TEXTS.modalResetButton}
          </EloButton>
        </div>
      </EloModal>
    </div>
  )
}

export default ApiBranch
