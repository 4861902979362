import { RefObject } from 'react'
import ReCaptcha from 'react-google-recaptcha'

import { patchLink } from 'utils/link.utils'

import { isNumber, isString } from './validators.utils'

interface LinkData {
  seller?: {
    protocol?: string
    host: string
    username?: string
  }
  sellerName: string
  slug: string
}

type Item = Record<string, string>

interface SortableItem {
  position: number
}

export const createSorter =
  (condition: boolean, additionalKey?: string, additionalCondition?: boolean) =>
  (list: Item[] = [], key = 'position'): Item[] => {
    const accumulator = {
      listToSort: [],
      listToKeep: [],
    }
    const { listToSort, listToKeep } = list.reduce((result, item) => {
      const value = item[key]
      const arrayToPush = isNumber(value) || isString(value) ? 'listToSort' : 'listToKeep'
      return {
        ...result,
        [arrayToPush]: [...result[arrayToPush], item],
      }
    }, accumulator)

    return [
      ...listToSort.sort((a: object, b: object) => {
        const additonalSort = additionalCondition
          ? a[additionalKey as keyof typeof a] - b[additionalKey as keyof typeof b]
          : b[additionalKey as keyof typeof b] - a[additionalKey as keyof typeof a]
        const ascSort = additionalKey
          ? a[key as keyof typeof a] - b[key as keyof typeof b] || additonalSort
          : a[key as keyof typeof a] - b[key as keyof typeof b]
        const descSort = additionalKey
          ? b[key as keyof typeof b] - a[key as keyof typeof a] || additonalSort
          : b[key as keyof typeof b] - a[key as keyof typeof a]
        return condition ? ascSort : descSort
      }),
      ...listToKeep,
    ]
  }

export const ascendingSort = createSorter(true)

export const ascendingSortWithSecondDesc = createSorter(true, 'id', false)

export const mapToIdsArray = (array: { id?: number }[] = []): Array<number | object> =>
  array.map((item) => item?.id || item)

export const getArrayItemById = <T>(array: ({ id?: number } & T)[] = [], id = '') =>
  array.find((item) => String(item?.id) === String(id))

export const randomWithMinMax = (min: number, max: number): number => Math.floor(Math.random() * (max - min)) + min

export const getSellerLink = (
  seller: { host?: string; username?: string; protocol?: string },
  slug: string | undefined
): string => `${seller?.protocol}://${seller?.host}/s/${seller?.username}${slug ? `/${slug}` : ''}`

export const getWindow = () => {
  if (typeof window !== 'undefined') {
    return window
  }

  return {}
}

export const getDocument = () => {
  if (typeof document !== 'undefined') {
    return document
  }

  return {}
}

export const sortItemsByPositions = (keys: string[], list: { id: number }[]) =>
  keys.map((key) => list.find(({ id }) => String(id) === String(key))).filter(Boolean)

export const getPayerSellerLink = (linkData: LinkData, ROOT_URL = ''): string => {
  const sellerData = linkData?.seller
  const rootUrl = sellerData ? `${sellerData.protocol}://${sellerData.host}` : patchLink(ROOT_URL)
  const sellerUsername = sellerData ? sellerData.username : linkData?.sellerName

  return patchLink(
    linkData?.slug?.length
      ? `${rootUrl}/payer/s/${sellerUsername}/${linkData?.slug}`
      : `${rootUrl}/payer/s/${sellerUsername}`
  )
}

export const sortByPosition = (a: SortableItem, b: SortableItem) => {
  const keyA = a.position
  const keyB = b.position

  if (keyA < keyB) {
    return -1
  }

  if (keyA > keyB) {
    return 1
  }

  return 0
}

export const invokeRecaptcha = async (recaptchaRef: RefObject<ReCaptcha>) => {
  recaptchaRef.current.reset()
  return await recaptchaRef.current.executeAsync()
}

export const openUrlInNewTab = (url = '', target = '_PRODUCT') => {
  setTimeout(() => {
    const a = document.createElement('a')

    document.body.appendChild(a)
    a.setAttribute('style', 'display: none')
    a.setAttribute('href', url)
    a.setAttribute('target', target)
    a.setAttribute('rel', 'external noopener')
    a.click()
    a.parentNode.removeChild(a)
  })
}

export const handleBlur = (
  searchValue: string,
  shouldUpdateList: boolean,
  resetSearch: () => void,
  handleSearch: (value: string) => void
) => {
  if (searchValue === '' || searchValue === null) {
    resetSearch()
  }
  if (shouldUpdateList) {
    handleSearch(searchValue)
  }
}
